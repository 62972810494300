import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthServiceService } from '../auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorTokenService  implements HttpInterceptor {

  constructor(
    private toastr: ToastrService,
    private authService: AuthServiceService,
    
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const excludedUrls = [
      '/register',
      '/login',
      '/verify-otp',
      '/otpVerification',
      '/forgotPassword',
      '/resetPassword',
    ];
    const isExcluded = excludedUrls.some(url => req.url.includes(url));

    let token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (!isExcluded && token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!navigator.onLine) {
          this.toastr.error('You have lost internet connection!', 'Connection Error');
        } else if (error.status === 0) {
          this.toastr.error('No response from the server. Please try again later.', 'Server Error');
        }  else if (error.status === 401) {
          // Handle 401 Unauthorized error here
          this.authService.logout();

        } 
        else {
          // this.toastr.error(error.message, 'Error');
        }
        return throwError(error);
      })
    );
  }
}