import {  AfterViewInit, Component, OnInit } from '@angular/core';
import { initFlowbite, initModals } from 'flowbite';
import { initDrawers } from 'flowbite';
import 'flowbite';
import { Flowbite } from './flowbite.decorator';
@Flowbite()

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
// @Flowbite();
export class AppComponent implements OnInit, AfterViewInit {
  constructor() {}

  title = 'seaca';
  ngOnInit() {
    initFlowbite();
    initModals();

  }
  ngAfterViewInit() {
    initFlowbite();
  }
 
}
