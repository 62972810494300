import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GuideGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    const hasModalBeenShown = localStorage.getItem('modalTournamentShown') === 'true';
    if (hasModalBeenShown) {
      // Redirect to some other route, e.g., home page or a 'not allowed' page
      return this.router.createUrlTree(['/']);
    }
    return true;
  }
}
