import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  private isUserOnlineSubject = new BehaviorSubject<boolean>(false);
  isUserOnline$ = this.isUserOnlineSubject.asObservable();
  api_base_url = environment.apiUrl

  constructor( private router : Router,
    private http: HttpClient
  ) {
    // Check if token exists on initialization
    this.updateUserStatus();
  }

  updateUserStatus() {
    let token;
    if( localStorage.getItem('token')){

      token = localStorage.getItem('token');
    }else if(sessionStorage.getItem('token')){
      token = sessionStorage.getItem('token');

    }
    const isUserOnline = !!token; // Converts token to boolean
    this.isUserOnlineSubject.next(isUserOnline);
  }

  isUserOnline(): boolean {
    return this.isUserOnlineSubject.value;
  }


  register(user: any) {
    return this.http.post(`${this.api_base_url}/user/register`, user);
  }
  verifyOtp(otp: any) {
    return this.http.post(`${this.api_base_url}/user/verifyOtp`, otp);
  }
  verifyOtpCreate(otp: any,token:any) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.post(`${this.api_base_url}/user/verifyOtp`, otp, { headers });
  }
  verifyOtpForForgotPassword(otp: any) {
    return this.http.post(`${this.api_base_url}/user/otpVerification`, otp);
  }
  forgotPassword(email: any) {
    return this.http.post(`${this.api_base_url}/user/forgotPassword`, email);
  }
  resendOtp(data: any) {
    return this.http.post(`${this.api_base_url}/user/resendOtp`, data);
  }
  changeResendOtp(data: any) {
    return this.http.post(`${this.api_base_url}/user/changeResendOtp`, data);
  }
  changeResendOtpCreate(data: any,token:any) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.post(`${this.api_base_url}/user/changeResendOtp`, data, { headers });
  }
  sendOtp(data: any) {
    return this.http.post(`${this.api_base_url}/user/sendOtp`, data);
  }
  sendOtpCreate(data: any,token:any) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.post(`${this.api_base_url}/user/sendOtp`, data, { headers });
  }
  changePassword(data: any) {
    return this.http.post(`${this.api_base_url}/user/changePassword`, data);
  }
  resetPassword(data: any,token:any) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post(`${this.api_base_url}/user/resetPassword`, data, { headers });
  }
  login(user: any) {
    return this.http.post(`${this.api_base_url}/user/login`, user);

  }
  getRegion() {
    return this.http.get(`${this.api_base_url}/user/regionList`);

  }
  notificationList() {
    return this.http.get(`${this.api_base_url}/user/notificationList`);

  }
 

  logout() {
    // Remove token from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    this.router.navigate(['/']).then(() => {
      // Refresh the page
      window.location.reload();
    });
    // Update isUserOnline state
    this.isUserOnlineSubject.next(false);
  }
}
