import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuideGuard } from './core/guard/guide.guard';
import { AuthGuard } from './core/guard/auth-guard.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./modules/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sidebar',
    loadChildren: () =>
      import('./modules/core/commonn.module').then((m) => m.CommonnModule),
  },
  {
    path: 'tournament',
    loadChildren: () =>
      import('./modules/tournament/tournament.module').then((m) => m.TournamentModule),
  },
  {
    path: 'liveStream',
    loadChildren: () =>
      import('./modules/live-stream/live-stream.module').then((m) => m.LiveStreamModule),
  },
  {
    path: 'jobListing',
    loadChildren: () =>
      import('./modules/job-listing/job-listing.module').then((m) => m.jobListingModule),
  },
  {
    path: 'educationalVideos',
    loadChildren: () =>
      import('./modules/educational-videos/educational-videos.module').then((m) => m.EducationalVideosModule),
  },
  {
    path: 'guide',
    loadChildren: () =>
      import('./modules/tournament-guide/tournament-guide.module').then((m) => m.TournamentGuideModule),
    canActivate: [GuideGuard]
  },
  {
    path: 'community',
    loadChildren: () =>
      import('./modules/community/community.module').then((m) => m.CommunityModule),
  },
  {
    path: 'game-category',
    loadChildren: () =>
      import('./modules/game-category/game-category.module').then((m) => m.GameCategoryModule),
  },
  {
    path: 'calendar',
    loadChildren: () =>
      import('./modules/calendar/calendar.module').then((m) => m.CalendarModule),
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
